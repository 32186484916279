import { IEventLocation, IEventResponse } from "../api/apiResponseTypes";

export class Event {
  id: number;
  inviteCode: string;
  teacherInviteCode: string;
  teacherInviteExpiresAt: number;
  name: string;
  shareLink: string;
  location: IEventLocation;
  locationInfo: string;
  displayCountry: string;
  end: number;
  membersCount: number;
  personalMessage: string;
  created: number;
  summary: string;
  video?: string;
  photo?: string;
  photo2?: string;
  city: string;
  country: string;
  closed: boolean;

  currency: string;

  locationId: number;
  schoolName: string;
  zip: string;
  state: string;

  hideComments: boolean;
  showDonations: boolean;
  accessToDonationPage: boolean;

  fundraising: number;
  fundraisingPerMember: number;
  readingBooks: number;
  readingBooksEnabled: number;
  readingBooksPerMember: number;
  readingMins: number;
  readingMinsEnabled: number;
  readingMinsPerMember: number;

  raisedSum: number;
  booksSum: number;
  donationsCount: number;
  recordsCount: number;
  minsSum: number;
  // minsSum: number;

  stripeEmail?: string;
  stripeAccount?: StripeAccount;
  accountId?: string;


  objectives?: string[];

  constructor(response: IEventResponse) {
    this.id = response.id;
    this.inviteCode = response.inviteCode;
    this.teacherInviteCode=response.teacherInviteCode;
    this.teacherInviteExpiresAt = response.teacherInviteExpiresAt;
    this.name = response.name;
    this.membersCount = response.membersCount;
    this.shareLink = response.shareLink;
    this.locationInfo = `${response.location.schoolName}, ${response.location.displayState || ''} ${response.location.zip}, ${response.location.country}`;
    this.location = response.location;
    this.created = response.created;
    this.displayCountry = response.location.displayCountry;
    this.end = response.end;
    this.personalMessage = response.details.personalMessage;
    this.summary = response.details.summary;
    this.video = response.details.video;
    this.photo = response.details.photo;
    this.photo2 = response.details.photo2;
    this.city = response.location.city;
    this.country = response.location.country;
    this.closed = response.closed;
    this.state = response.location.displayState;
    this.currency = response.currency;

    this.locationId = response.location.id;
    this.schoolName = response.location.schoolName;
    this.zip = response.location.zip;

    this.hideComments = response.options.hideComments;
    this.showDonations = response.options.showDonations;
    this.accessToDonationPage = response.options.accessToDonationPage;

    //goals
    this.fundraising = response.goals.fundraising;
    this.fundraisingPerMember = response.goals.fundraisingPerMember;
    this.readingBooks = response.goals.readingBooks;
    this.readingBooksEnabled = response.goals.readingBooksEnabled;
    this.readingBooksPerMember = response.goals.readingBooksPerMember;
    this.readingMins = response.goals.readingMins;
    this.readingMinsEnabled = response.goals.readingMinsEnabled;
    this.readingMinsPerMember = response.goals.readingMinsPerMember;

    //summary
    this.raisedSum = response.summary.raisedSum;
    this.donationsCount = response.summary.donationsCount;
    this.booksSum = response.summary.booksSum;
    this.recordsCount = response.summary.recordsCount;
    this.minsSum = response.summary.minsSum;

    this.stripeAccount = response.stripeAccount;
    this.accountId = response.stripeAccount?.accountId;
    this.objectives = response.details.objectives;
  }

  public static toRequestBody(event?: Event): IEventResponse {
    return {
      id: event?.id,
      inviteCode: event?.inviteCode,
      name: event?.name,
      shareLink: event?.shareLink,
      membersCount: event?.membersCount,
      end: event?.end,
      currency: event?.currency,
      closed: event?.closed,

      goals: {
        fundraising: event?.fundraising,
        fundraisingPerMember: event?.fundraisingPerMember,
        readingBooks: event?.readingBooks,
        readingBooksEnabled: event?.readingBooksEnabled,
        readingBooksPerMember: event?.readingBooksPerMember,
        readingMins: event?.readingMins,
        readingMinsEnabled: event?.readingMinsEnabled,
        readingMinsPerMember: event?.readingMinsPerMember,
      },

      options: {
        accessToDonationPage: event?.accessToDonationPage,
        hideComments: event?.hideComments,
        showDonations: event?.showDonations,
      },

      details: {
        personalMessage: event?.personalMessage,
        summary: event?.summary,
        video: event?.video,
        photo: event?.photo,
      },

      location: {
        id: event?.locationId,
        zip: event?.zip,
        city: event?.city,
        schoolName: event?.schoolName,
        state: event?.state,
        country: event?.country,
      },

      summary: {
        raisedSum: event?.raisedSum,
        recordsCount: event?.recordsCount,
        readingMins: event?.minsSum,
      },

    } as IEventResponse;
  }
}

interface StripeAccount {
  email: string;
  live: boolean,
  accountId: string;
  displayName: string;
  businessModel: string;
}

import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {Route, Switch, useHistory, withRouter} from "react-router";
import {Drawer} from "@material-ui/core";

import Sidebar from "../Sidebar/Sidebar";
import DesktopNav from "../DesktopNav/DesktopNav";
import Header from "./Header/Header";
import Overview from "../../../screens/Overview/Overview";
import Students from "../../../screens/Students/Students";
import EditStudent from "../../../screens/Students/StudentEditForm/StudentEditForm";
import Teams from "../../../screens/Teams/Teams";
import Donations from "../../../screens/Donations/Donations";
import Announcement from "../../../screens/Announcement/Announcement";
import Fundraiser from "../../../screens/Fundraiser/Fundraiser";
import {withStyles} from "@material-ui/core/styles";
import {Path} from "../../../consts/router";

import styles from "./AppLayout.module.scss";
import TeamEdit from "../../../screens/Teams/TeamsEdit/TeamEdit";
import AccountSettings from "../../../screens/AccountSettings/AccountSettings";
import InviteDialog from "../../../screens/Students/InviteDialog/InviteDialog";
import TeamMembersStore from "../../../store/TeamsStores/TeamMembersStore";
import EventStore from "../../../store/EventStore";
import UserStore from "../../../store/UserStore";
import TeamStore from "../../../store/TeamsStores/TeamStore";
import {ACCESS_TOKEN, ACCESS_TOKEN_URL_PARAM, AFFILIATE_PARTNER_ID, signUpData,} from "../../../consts/localStorage";
import AppStore from "../../../store/AppStore";
import DonationsStore from "../../../store/DonationsStore";
import {useIntercom} from "react-use-intercom";
import {IntercomHelper} from "../../../utils/intercomHelper";
import Teachers from "../../../screens/Teachers/Teachers";

const StyledDrawer = withStyles({
  root: {
    "& .MuiPaper-root": {
      backgroundColor: "transparent",
    },

    "& .MuiPaper-elevation5": {
      boxShadow: "none",
    },
  },
})(Drawer);

const AppLayout = observer(() => {
  const history = useHistory();
  const [menuOpen, setMenuOpen] = useState(false);
  const { boot: bootIntercom, update: updateIntercom } = useIntercom();


  useEffect(() => {
    const url = new URL(window.location.href);
    const accessToken = url.searchParams.get(ACCESS_TOKEN_URL_PARAM);

    if (accessToken) {
      const affiliatePartnerId = localStorage.getItem(AFFILIATE_PARTNER_ID);
      localStorage.clear();
      if (affiliatePartnerId)
        localStorage.setItem(AFFILIATE_PARTNER_ID, affiliatePartnerId);
      localStorage.setItem(ACCESS_TOKEN, accessToken);
      history.push(Path.main);
    }

    if (!localStorage.getItem(ACCESS_TOKEN)) {
      history.push(Path.signIn);
      AppStore.setLayout();
      return;
    }


    UserStore.fetchData().then((user) => {
      if (!user) return;

      if (!user?.events?.length) {
        const lsData = JSON.parse(localStorage.getItem(signUpData) || "{}");

        // if (lsData?.businessModel) {
          if (
            // lsData?.businessModel &&
            lsData?.country &&
            lsData?.schoolName &&
            lsData?.type &&
            lsData?.zip
          ) {
            history.push(Path.signUpFundraiserInfo);
          } else {
            history.push(Path.signUpSchoolInfo);
          }
        // }
      // else {
      //     history.push(Path.signUpPricingModel);
        // }

        AppStore.setLayout();

        return;
      }

      if (!user?.displayName) {
        history.push(Path.signUpProfileInfo);
        AppStore.setLayout();
        return;
      }

      bootIntercom(
        IntercomHelper.getBootProps(user, styles.primaryColor)
      );


      TeamStore.fetchItemsList();
      TeamMembersStore.fetchTeamMember();
      DonationsStore.fetchDataList();

      setTimeout(() => {
        if (EventStore.event) updateIntercom(IntercomHelper.getUpdateProps(EventStore.event))
      }, 1000)
    });
  }, [history, updateIntercom, bootIntercom]);

  return (
    <>
      <div className={styles.AppLayout}>
        <StyledDrawer
          anchor="left"
          open={menuOpen}
          elevation={5}
          onClose={() => setMenuOpen(false)}
        >
          <Sidebar onClose={() => setMenuOpen(false)}/>
        </StyledDrawer>

        <DesktopNav onMenuClick={() => setMenuOpen(true)}/>

        <Header/>

        <div className={styles.screen}>
          <Switch>
            <Route exact path={Path.main} component={Overview}/>
            <Route exact path={Path.fundraiser} component={Fundraiser}/>
            <Route exact path={Path.students} component={Students}/>
            <Route exact path={Path.studentsEdit} component={EditStudent}/>
            <Route exact path={Path.donations} component={Donations}/>
            <Route exact path={Path.announcement} component={Announcement}/>

            <Route exact path={Path.teachers} component={Teachers}/>
            <Route exact path={Path.teams} component={Teams}/>
            <Route exact path={Path.teamsCreate} component={TeamEdit}/>
            <Route exact path={Path.teamsEdit} component={TeamEdit}/>

            <Route exact path={Path.account} component={AccountSettings}/>
          </Switch>
        </div>

        <InviteDialog
          isOpen={TeamMembersStore.isOpenInviteDialog}
          onClose={TeamMembersStore.handleCloseInviteDialog}
          shareLink={EventStore.event?.shareLink || ""}
          inviteCode={EventStore.event?.inviteCode || ""}
        />
      </div>
    </>
  );
});

export default withRouter(AppLayout);

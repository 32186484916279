import { action, computed, makeAutoObservable, observable } from "mobx";
import moment from "moment";
import { apiClient } from "../api/api";
import { IPaymentsByDate, IReadingByDate } from "../api/apiResponseTypes";
import { DAY_MONTH_DATE_FORMAT, FULL_DATE_FORMAT } from "../consts/dateFormats";
import EventStore from "./EventStore";

class OverviewStore {
  @observable public paymentLoading: boolean = false;

  @observable public readingLoading: boolean = false;

  @observable public paymentItems: IPaymentsByDate[] = [];

  @observable public readingItems: IReadingByDate[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  @action.bound
  public fetchPaymentItemsList = async () => {
    try {
      this.paymentLoading = true;
      const data = await apiClient.getPaymentsByDate(EventStore.eventId);
      this.paymentItems = data;
    } catch (e) {
      console.error("TeamStore.fetchTeamsList", e);
    } finally {
      this.paymentLoading = false;
    }
  };

  @action.bound
  public fetchReadingItemsList = async () => {
    try {
      this.readingLoading = true;
      const data = await apiClient.getReadingByDate(EventStore.eventId);
      this.readingItems = data;
    } catch (e) {
      console.error("TeamStore.fetchReadingItemsList", e);
    } finally {
      this.readingLoading = false;
    }
  };

  @computed
  public getPaymentChartData = () => {
    const obj = this.paymentItems?.map((item) => ({
      date: item.date,
      displayDate: moment(item.date).format(DAY_MONTH_DATE_FORMAT),
      amount: item.amount,
    }));

    if (obj?.length < 30 && obj.length >= 1) {
      for (let i = obj.length; i < 30; i++) {
        obj.unshift({
          amount: 0,
          date: moment(obj[0].date).subtract(1, "day").valueOf(),
          displayDate: moment(obj[0].date)
            .subtract(1, "day")
            .format(DAY_MONTH_DATE_FORMAT),
        });
      }
    }

    return obj;
  };

  @computed
  public getReadingChartData = () => {
    const obj = this.readingItems?.map((item) => ({
      date: item.date,
      displayDate: moment(item.date).format(DAY_MONTH_DATE_FORMAT),
      minutes: item.minutes,
    }));

    if (obj.length < 30 && obj.length >= 1) {
      for (let i = obj.length; i < 30; i++) {
        obj.unshift({
          minutes: 0,
          date: moment(obj[0].date).subtract(1, "day").format(FULL_DATE_FORMAT),
          displayDate: moment(obj[0].date)
            .subtract(1, "day")
            .format(DAY_MONTH_DATE_FORMAT),
        });
      }
    }

    return obj;
  };

  @computed
  public getRaisedPercent = (): number => {
    return (
      (EventStore.event?.raisedSum! / EventStore.event?.fundraising!) * 100
    );
  };

  @computed
  public getReadingPercent = (): number => {
    const value =
      (EventStore.event?.minsSum! / EventStore.event?.readingMins!) * 100;

    return value;
  };

  @computed
  public handleRedirectToStripe = async () => {
    const data = await apiClient.stripeConnection(window.location.href);
    window.open(data.connectUrl, '_blank');
    return false;
  };
}

export default new OverviewStore();

import React from "react";
import { Button } from "../../../components/common";

import { Event } from "../../../models/Event";
import MediaContainer from "../../../components/MediaContainer/MediaContainer";
import { dayToEnd, replaceLinks } from "../../../utils/common";

import { Divider } from "@material-ui/core";
import { ReactComponent as LocationIcon } from "../../../../images/icons/location.svg";
import { ReactComponent as EditIcon } from "../../../../images/icons/editPen.svg";
import { ReactComponent as EyeIcon } from "../../../../images/menuIcons/eye.svg";
import { ReactComponent as CloseIcon } from "../../../../images/icons/fundraiser/closeFundraiser.svg";
import styles from "./FundraiserDetails.module.scss";
import strings from "../../../../i18n/strings";
import EventStore from "../../../store/EventStore";
import DialogsStore, { Dialogs } from "../../../store/DialogsStore";
import { v4 } from "uuid";

interface IProps {
  eventData?: Event;
  isClosed?: boolean;
  onChangeViewMode: () => void;
}

const FundraiserDetails = ({
  eventData,
  isClosed,
  onChangeViewMode,
}: IProps) => {
  const objectives = EventStore.event?.objectives;
  const dayToEndFundraiser = dayToEnd(eventData?.end);

  const onOpenConfirmCloseFundraiserDialog = () =>
    DialogsStore.setActiveDialog(Dialogs.FUNDRAISER_CLOSE_CONFIRM);

  return (
    <>
      <div className={styles.headerWrapper}>
        <div className={styles.headerInfo}>
          <div className={styles.location}>
            <div className={styles.locationInfo}>
              <LocationIcon />
              <a
                href={`https://www.google.ru/maps/search/${eventData?.location.schoolName},${eventData?.location.zip} ${eventData?.location.country}`}
                rel="noreferrer"
                target="_blank"
              >
                {eventData?.locationInfo}
              </a>
            </div>

            {!isClosed && (
              <div className={styles.btnWrapper}>
                <Button
                  type="primary"
                  onClick={onChangeViewMode}
                  startIcon={<EditIcon fill="white" />}
                >
                  {strings.buttons.edit}
                </Button>

                <a
                  href={EventStore.event?.shareLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button type="accent" startIcon={<EyeIcon fill="white" />}>
                    {strings.buttons.preview}
                  </Button>
                </a>
              </div>
            )}
          </div>

          <div className={styles.name}>{eventData?.name}</div>

          <div className={styles.descriptionWrapper}>
            <div className={styles.description}>
              {isClosed ? (
                <span className={styles.dayToEnd}>
                  {strings.fundraiser_details.header.closed_description}
                </span>
              ) : (
                <>
                  {strings.formatString(
                    strings.fundraiser_details.header.description,
                    <span
                      className={styles.dayToEnd}
                    >{`${dayToEndFundraiser} days left`}</span>
                  )}
                </>
              )}
            </div>

            <div className={styles.closeBtn}>
              {dayToEndFundraiser === 0 && !isClosed && (
                <Button
                  border
                  borderColor="red"
                  textColor="red"
                  startIcon={<CloseIcon />}
                  width={200}
                  onClick={onOpenConfirmCloseFundraiserDialog}
                >
                  {strings.fundraiser_close.close_fundraiser}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>


      <Divider className={styles.divider} />

      <div className={styles.bodyWrapper}>
        <div className={styles.bodyTitle}>
          {strings.fundraiser_details.fundraiser_story}
        </div>

        <div className={styles.mediaWrapper}>
          <MediaContainer type="image" mode="view" url={eventData?.photo} />
          <MediaContainer type="image" mode="view" url={eventData?.photo2} />
          <MediaContainer
            className={styles.videoWrapper}
            type="video"
            mode="view"
            url={eventData?.video}
          />
        </div>

        <div className={styles.bodyText}  dangerouslySetInnerHTML={{__html: replaceLinks(eventData?.summary)}}/>

        {objectives && objectives.length > 0 && (
          <div className={styles.fundraiserObjectivesWrapper}>
            <div className={styles.bodyTitle}>
              {strings.fundraiser_details.fundraiser_objectives}
            </div>

            <ul className={styles.objectivesItemsWrapper}>
              {objectives.map((item) => (
                <li key={v4()}>{item}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default FundraiserDetails;

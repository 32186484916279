import { action, computed, makeAutoObservable, observable, } from 'mobx';
import { apiClient } from "../api/api";

import { IAchievement, IEventResponse } from '../api/apiResponseTypes';
import { Event } from '../models/Event';
import { EVENT_ID } from "../consts/localStorage";
import FundraiserDetailsStore from './FundraiserStores/FundraiserDetailsStore';
import AppStore from './AppStore';

class EventStore {
  @observable public eventId: number = Number(window.localStorage.getItem(EVENT_ID));

  @observable public event?: Event;

  @observable public achievements: IAchievement[] = [];

  @observable public loading?: boolean;

  constructor() {
    makeAutoObservable(this)
  }

  @action
  public setEventId = (eventId: number) => {
    if (eventId > 0) {
      window.localStorage.setItem(EVENT_ID, eventId.toString());
      this.eventId = eventId;
      this.fetchEventData()
    }
  }

  @computed
  public fetchEventData = async (eventId?: string) => {
    try {
      this.loading = true;
      const response: IEventResponse = await apiClient.getEventData(eventId || this.eventId);
      this.event = new Event(response);

      AppStore.setCurrency(response.currency);
      FundraiserDetailsStore.photoUrl = this.event.photo;
      FundraiserDetailsStore.videoUrl = this.event.video;
    } catch (e) {
      console.error("EventStore.fetchEventData", e);
    } finally {
      this.loading = false;
    }
  }

  public getAchievementsNames(ids: number[]): string {
    if (ids.length === 0) return 'None';
    let result = "";
    ids.forEach(id => {
      const a = this.achievements.find(a => a.id === id);
      if (a) result += a.name + ", ";
    })
    return result.substring(0, result.lastIndexOf(","));
  }

  @computed
  public fetchAchievements = async () => {
    try {
      this.achievements = await apiClient.getAchievements(this.eventId);
    } catch (e) {
      console.error("EventStore.fetchEventData", e);
    }
  }

  @computed
  public fetchEventByInviteCode = async (inviteCode: string) => {
    return await apiClient.getEventByInviteCode(inviteCode);
  }
}

export default new EventStore();
